/*-----------------------------Paneles-------------------------------*/
.mainPanel {
    display: flex;
    flex-direction: row;
    height: auto;
}
.mainPanel * {
    /*
    border: 1px solid black;
    */
}
.leftPanel,
.centralPanel,
.rightPanel {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: stretch;
    padding-left: 30px;
    padding-right: 30px;
}

.leftPanel,
.rightPanel {
    width: 20%;
    top: 0;
    padding-bottom: 30px;
}

.centralPanel {
    width: 60%;
    background-color: white;
    padding-left: 0px;
    padding-right: 0px;
}
/*-----------------------------Panel Izquierdo-------------------------------*/
.leftPanel img {
    cursor: pointer;
    
}

.redesSociales {
    display: flex;
    flex-direction: column;
    margin-top: 0;
}
.redesSociales a {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    gap: 1vw;
    text-decoration: none;
    box-sizing: border-box;
    font-family: "GarnetCapitalsThin";
    color: black;
    overflow: wrap;
    font-size: small;
    padding: 10px;
}
.redesSociales a:first-child {
}
.redesSociales a:hover {
    text-decoration: underline;
    background-color: #9999992c;
}
.redesSociales img {
    height: 50px;
    filter: drop-shadow(0px 0px 4px black);
}
.plataformas h1{
    margin: 0;
    font-family: "GarnetCapitalsBold";
    font-size: small;
}
.plataformas p{
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: gray;
}
.reporte_embalses{
    align-self: center;
    cursor: pointer;
    box-shadow: 0px 0px 4px black;
    border-radius: 1vw;
    width: 100%;
}
/*-----------------------------Noticia principal-------------------------------*/
.centralPanelNoticias {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 100px;
}
.separadorPrincipal {
    border: none;
    border-top: 5px solid #333;
    margin: 20px 0;
}

.contenedorNoticias{
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 15px;
    margin: 5px;
    cursor: pointer;
}

.contenedorNoticias:hover {
    background-color: #9999992c;
}
.tituloPrincial {
    font-family: "GarnetCapitalsBold";
    font-size: 20px;
    text-align: center;
    color: black;
    margin-top: 0px;
    margin-bottom: 20px;
}
.tituloPrincialNoticias {
    font-family: "GarnetCapitalsBold";
    font-size: 20px;
    text-align: center;
    color: black;
    margin-top: 0px;
    margin-bottom: 0px;
}

.tituloNoticias {
    font-family: "GarnetCapitalsThin";
    font-size: 15px;
    text-align: center;
    color: black;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
}
.fechaNoticia {
    font-family: "GarnetCapitalsThin";
    font-size: medium;
    text-align: center;
    color: #9A1D17;
    margin-top: 0px;
    margin-bottom: 10px;
}
.imagenDescripcion {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    justify-content: center;
    align-self: center
}
.imagenNoticia {
    max-height: 200px;
    cursor: pointer;
    box-shadow: 0px 0px 4px black;
    border-radius: 1vh;
    align-self: center;
}
.descripcionNoticia {
    margin: 0px;
    text-align: justify;
    padding: 10px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: medium;
    
}
.separadorSecundario {
    border: none;
    border-top: 1px solid #333;
    margin: 20px 0;
    align-self: normal;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.modal-noticias {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 40%;
    height: 80%;
    padding: 20px;
    border-radius: 4px;
    overflow-y: auto;
}

.modal-noticias img {
    width: 80%;
    float: left;
    display: block;
    align-self: center;
    box-shadow: 0px 0px 4px black;
    border-radius: 1vh;
    margin-bottom: 10px;
}

.modal-noticias h1 {

    font-family: "GarnetCapitalsThin";
    font-size: large;
    text-align: center;
    color: black;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
}

.modal-noticias h2 {
    font-family: "GarnetCapitalsThin";
    font-size: medium;
    text-align: center;
    color: #9A1D17;
    margin-top: 0px;
    margin-bottom: 10px;
}

.modal-noticias i {
    font-size: medium;
    text-align: justify;
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "Montserrat", sans-serif;
    white-space: pre-wrap;
}
.modal-noticias p {
    font-size: medium;
    text-align: justify;
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "Montserrat", sans-serif;
    white-space: pre-wrap;
}

/*-----------------------------Panel Derecho-------------------------------*/
.tituloDerecha {
    font-family: "GarnetCapitalsBold";
    font-size: xx-large;
    text-align: center;
    color: black;
    margin-top: 0px;
    margin-bottom: 30px;
}
.rightPanel img {
    cursor: pointer;
    
}
.imagenEventos {
    align-self: center;
    width: 100%;
    box-shadow: 0px 0px 4px black;
    border-radius: 20px;
}
.contenedorEventos {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
/*-----------------------------PROMMRA en prensa-------------------------------*/
.prensa {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    gap: 1vw;
    padding: 10px;
    border-top: 1px solid #333;
}
.prensa:last-child {
    border-bottom: 1px solid #333;
}

.prensa:hover {
    cursor: pointer;
    background-color: #9999992c;
}
.imagenPrensa {
    height: 50px;
    box-shadow: 0px 0px 4px black;
    border-radius: 10px;
}
.prensa a {
    font-family: "GarnetCapitalsRegular";
    font-size: small;
    text-align: left;
    align-items: left;
    color: #550514;
    text-decoration: none;
    margin: 0;
}
.prensa a:hover {
    text-decoration: underline;
}
/* Animaciones para el modal con difuminado */
.modal-enter {
    opacity: 0;
}

.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.modal-exit {
    opacity: 1;
}

.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

/* Opcionalmente, estilos para los overlays */
.modalGeneral,
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
/*-----------------------------Responsive-------------------------------*/
@media (max-width: 1200px) {

}
@media (max-width: 1100px) {

    .modal-noticias {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 20px;
        width: 70%;
        max-height: 70%;
    }

    .modal-noticias img {
        width: 100%;
        max-width: 350px;
        padding: 0;
    }

    .modal-noticias h1 {
        text-align: center;
        font-size: large;
    }

    .modal-noticias h2 {
        text-align: center;
        font-size: large;
    }

    .titulo {
        text-align: justify;
        font-size: medium;
    }
}
@media (max-width: 900px) {
    .mainPanel {
        flex-direction: column;
    }

    .centralPanel {
        order: 1;
        width: 100%;
    }

    .imagenNoticia {
        max-width: 100vh;
    }

    .leftPanel {
        order: 2;
        width: 100%;
        padding-bottom: 30px;
    }

    .rightPanel {
        order: 3;
        width: 100%;
    }
    .imagenEventos {
        width: 50%;
    }
    .reporte_embalses {
        width: 50%;
    }
}
@media (max-width: 600px) {
    .contenedorNoticias {
        flex: 1 1 80%;
        width: 100%;
    }
    .contenedorNoticias img {
        width: 100%;
    }
    .imagenEventos {
        width: 100%;
    }

    .reporte_embalses {
        width: 100%;
    }
}
