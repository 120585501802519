.Dashboard {
    display: flex;
    flex-direction: row;
    height: 100vh;
    
}
.Dashboard * {
    /*
    border: 1px solid black;
    */
}
.Sidebar {
    
    background-image: linear-gradient(90deg, #a12326 20%, #570000);
    color: white;
    width: 300px;
    min-width: 300px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;

}
.Sidebar h1 {
    font-family: "GarnetCapitalsBold";
    font-size: x-large;
    padding: 10px;
    margin: 0;
    cursor: pointer;
}

.Sidebar h1:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Sidebar h3 {
    font-family: "GarnetCapitalsBold";
    font-size: medium;
    padding: 10px;
    margin: 0;
}
.Collapsible__trigger {
    display: block;
    font-size: large;
    
    text-align: left;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
}
.Collapsible__trigger:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
.Collapsible {
    font-family: "Montserrat", sans-serif;
    width: 100%;
    cursor: pointer;
    
}
.Collapsible button {
    text-align: center;
    width: 100%;
    background: none;
    border: none;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    color: white;
    text-align: left;
    padding: 10px 40px;
    
    font-size: small;
}
.Sidebar button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: underline;
}
.mainDashboard {
    display: block;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
}
.Topbar {
    background-image: linear-gradient(-90deg, #a12326 20%, #570000);
    color: white;
    font-family: "GarnetCapitalsBold";
    font-size: x-large;
    padding: 10px;
    margin: 0;
}