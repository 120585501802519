

.slider-container {
    width: 70%;
    margin: auto;
    position: relative;
}
.flex-container {
    gap: 10px;
}
.slider-container img {
    filter: drop-shadow(0px 0px 4px black);
    margin: 10px 10px;
    gap: 10px;
    width: 98%;
}
.slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.overlay {
    display: flex;
    flex-direction: column;
    align-items: left;
    position: absolute;
    top: 77%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    overflow: hidden;
    filter: drop-shadow(0px 0px 4px black);
}
.overlay p {
    display: flex;
    width: fit-content;
    max-width: 40%;
    padding: 0.8vw;
    background-image: linear-gradient(90deg, #a12326 20%, #570000);
    border-top-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
    border-top-right-radius: 1vw;
    margin: 0;
    color: white;
    font-family: "GarnetCapitalsBold";
    font-size: 1vw;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.overlay a {
    display: flex;
    width: fit-content;
    padding: 0.7vw;
    font-size: 0.8vw;
    color: black;
    font-family: "GarnetCapitalsRegular";
    background: white;
    text-decoration: none;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
    align-items: center;
}
.overlay a::after {
    content: '→';
    display: inline-block;
    margin-left: 0.5vw;
    font-size: 1vw;
}
.overlay a:hover {
    background: #e4e4e4;
    
}
.slick-prev,
.slick-next {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.slick-prev {
    left: 20px;
}
.slick-next {
    right: 40px;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    color: white;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.slick-dots {
    position: absolute;
    bottom: 6%;
    width: 100%;
    display: flex !important;
    justify-content: center;
}
.slick-dots li button:before {
    color: transparent;
    opacity: 1;
}
.slick-dots li.slick-active button:before {
    color: transparent;
}
.slick-dots li button {
    border: 3px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px black;
}
.slick-dots li.slick-active button {
    border-color: black;
    box-shadow: 0px 0px 10px white;
}
@media (max-width: 1000px) {
    .slider-container img {
        filter: none;
        border-radius: 10%;
    }
    .slider-container {
        width: 60%;
        margin: auto;
        overflow: hidden;
    }
    .overlay {
        top: 80%;
        width: 85%;
        padding: 1vw;
        filter: drop-shadow(0px 0px 4px black);
    }
    .overlay p {
        font-size: 1.8vw;
        max-width: 50%;
        padding: 2% 3%;
        border-top-left-radius: 2vw;
        border-bottom-right-radius: 2vw;
        border-top-right-radius: 2vw;
    }
    .overlay a {
        padding: 1.5% 3%;
        font-size: 1.6vw;
        border-bottom-left-radius: 2vw;
        border-bottom-right-radius: 2vw;
    }
    .overlay a::after {
        content: '→';
        display: inline-block;
        margin-left: 1.5vw;
        font-size: 2vw;
    }

}
@media (max-width: 600px) {
    .slider-container img {
        border-radius: 10%;
    }
    .slider-container {
        width: 90%;
        margin: auto;
        overflow: hidden;
    }
    .overlay {
        top: 85%;
        width: 85%;
        padding: 1vw;
        filter: drop-shadow(0px 0px 4px black);
    }
    .overlay p {
        font-size: 2.2vw;
        max-width: 50%;
        padding: 1.5% 3%;

    }
    .overlay a {

        font-size: 2vw;

    }
    .overlay a::after {
        font-size: 2.5vw;
    }

}
@media (max-width: 400px) {
    .overlay {
        top: 75%;
        width: 85%;
    }

    .overlay p {
        font-size: 3vw;
    }

    .overlay a {
        font-size: 2.5vw;
    }

    .overlay a::after {
        font-size: 3vw;
    }

}