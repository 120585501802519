
.navbar1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    background-image: url("../images/footer.svg");
    background-size: cover;
    background-position: center;
            
}
.panelLogo {
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 90px;
}
.navbar1 img {
    height:max-content;
}
.panelLogo a {
    align-items: center;
    width: 80%;
}

.logo {
    width: 100%;
    align-self: center;
}
.navbar1 a {
    color: white;
    text-decoration: none;
    padding: 20px 10px;
    font-family: "GarnetCapitalsThin";
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
}
.navbar1 a:hover {
    background-color: #ffffff25;
}
.panelLogo a:hover {
    background-color: transparent;
}
.hamburger1 {
    display: none;
    cursor: pointer;
}
.navbar1.open {
    flex-direction: column;
}
@media (max-width: 1050px) {

    .navbar1 {
        background-image: url("../images/footer.svg");
            background-size: cover;
            background-position: center;
    }
    
    .hamburger1 {
        display: block;
        text-align: center;
        font-size: xx-large;
    }
    .nav-items1 {
        width: 100%;
    }
    .nav-items1 a {
        display: none;
        font-family: "GarnetCapitalsThin";
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    }
    .navbar1.open .nav-items1 a {
        display: block;
        text-align: center;
    }
    .navbar1 {
        flex-direction: column;
        align-items: center;
    }
    .panelLogo{
        display: flex;
        justify-content: center;
        background-color: white;
        border-radius: 90px;
        margin-bottom: 8px;
        padding: 10px 0px;
    }
    .panelLogo a {
        align-items: center;
        width: 80%;
    }
    .logo {
        width: 100%;
        align-self: center;
    }
}