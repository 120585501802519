.segmented-progress-bar-container2 {
    display: flex;
    /* Alinea los elementos en una fila */
    align-items: center;
    /* Alinea los elementos verticalmente en el centro */
    gap: 10px;
    /* Añade un espacio entre la barra de progreso y el cuadro de porcentaje */
}
.segmented-progress-bar2 {
    position: relative;
    background-color: transparent;
    width: 100%;
}

.progress-bar-segment2 {
    width: 10%;
    height: 100%;
    display: inline-block;
    color: red;
    
}

.progress-bar-segment2:not(:last-child) {
    border-right: 3px solid white;
    height: 10px;
    /* Ajusta la altura del borde */
    margin-top: auto;
    /* Alinea el borde al centro verticalmente */
    margin-bottom: auto;
    /* Alinea el borde al centro verticalmente */
}

.progress-bar-overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    
}
.progress-bar2{
    display: flex;
    border: 4px solid gray;
    border-radius: 100px;
    
}
.progress-percentage2 {
    font-family: "GarnetCapitalsBold";
    font-size: small;
    background-color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 10px;
    width: fit-content;
    text-align: center;
    margin: 0px auto;
    padding: 5px;
    border: 2px solid black;
    /*
    box-shadow: 0px 0px 4px black;
    */
}