.Footer {
    display: flex;
    justify-content: row;
    align-items: center;
    height: 150px;
    background-image: url("../images/footer.svg");
    background-size: cover;
    background-position: center;
    padding: 1vh 0em;
}
.Footer * {
    /*
    border: 1px solid black;
    */
}
/*-----------------------------Panel Contacto------------------------------*/
.panelContacto {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    width: 25%;
    height: auto;
    padding-left: 20px;
    
}
.panelContacto div {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-bottom: 10px;
    gap: 10px;
}
.panelContacto h1 {
    color: white;
    font-family: "GarnetCapitalsBold";
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 2.5vh;
}
.panelContacto h2 {
    color: white;
    font-family: "GarnetCapitalsThin";
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    font-size: medium;
    margin-top: 0px;
    margin-bottom: 10px;
}
.panelContacto h3 {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    font-size: medium;
    margin-bottom: 0px;
    margin-top: 0px;
}

.panelContacto img {
    width: 5%;

}

/*-----------------------------Panel Departamentos------------------------------*/
.panelDepartamentos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    align-items: center;
}
.logosDpto {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.logosDpto div {
    width: 20%;
    margin: auto 0;
}
.logosDpto img {
    width: 100%;
}
.laboratorio{
    color: white;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-size: medium;
}
/*-----------------------------Panel Redes Sociales------------------------------*/
.panelRedes {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 25%;
    height: auto;
}
.siguenos{
    color: white;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: medium;
    margin-top: 0px;
    margin-left: 10%;
    margin-right: 10%;

}
.iconosRedes {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2em;

}
.iconosRedes a {
    display: flex;
    width: 40px;
}
.iconosRedes img {
    width: 100%;
}

.imagenDireccion {
    margin: auto 0;
}

@media (max-width: 1100px) {
    .Footer {
        flex-direction: column;
        height: auto;
    }
    .panelContacto {
        order: 1;
        width: 100%;
        margin: 20px 0;
        padding-left: 0px;
        align-items: center;
    }
    .panelDepartamentos {
        order: 2;
        width: 100%;
        margin: 20px 0;
    }
    .panelRedes {
        order: 3;
        width: 100%;
        margin: 20px 0;
    }
    .panelContacto h1 {
        text-align: center;
    }
    .panelContacto h3 {
        text-align: center;
    }
    .panelContacto div {
        justify-content: center;
    }
    .logosDpto {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .logosDpto div {
        width: 40%;
        margin: auto 0;
    }
    .logosDpto img {
        width: 100%;
        
    }
    .laboratorio {
        max-width: 600px;
        width: 90%;
    }
    .panelRedes {
        order: 3;
        width: 100%;
    }
}

@media (max-width: 300px) {
    .iconosRedes {
        flex-direction: column;
        align-items: center;
    }
    
}