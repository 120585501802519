.añosProyectos{
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 4px black;
    margin: 20px;
    margin-top: 0px;
    padding: 20px;
    min-height: 70vh;
}
.añosProyectos * {
    /*
    border: 1px solid black;
    */
}
.border{
    display: flex;
    flex-direction: row;
    border-top: 2px solid #333;
}
.años h2{
    font-family: "GarnetCapitalsBold";
    font-size: larger;
    width: 70%;
}
.años{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 20%;    
}
.años button{
    height: 40px;
    width: 35%;
    background: transparent;
    border: none;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
}

.años button:hover{
    background-color: #9999992c;
}
.años button:not(:last-child) {
    border-bottom: 1px solid #333;
}
.proyectos{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
}
.contenidoProyectos {
    display: flex;
    flex-direction: column;
    padding: 5px;
    min-height: 600px;
}

.react-swipeable-view-container{
    width: 100%;
}

.proyectos h2 {
    font-family: "GarnetCapitalsBold";
    font-size: larger;
}
.proyectos h3 {
    font-family: "GarnetCapitalsThin";
    font-size: medium;
}
.proyectos p {
    font-family: "Montserrat", sans-serif;
    text-align: justify;
    white-space: pre-wrap;

}
.cambiarPestaña{
    position: absolute;
}
.hamburgerAños {
    display: none;
    cursor: pointer;
    text-align: center;
    font-size: xx-large;
}
.botonesProyectos {
    display: flex;
    flex-direction: column;
    width: 80%;
}
.botonesAntSig{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
}
.botonesAntSig button {
    height: 40px;
    width: 10%;
    min-width: 100px;
    background: #9999992c;
    border: none;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: 1px solid #333;
}
.botonesAntSig button:disabled {
    border: none;
    cursor: auto ;
}
.botonesAntSig button:disabled:hover {
    background-color: #9999992c;
}
.botonesAntSig button:enabled:hover {
    background-color: #5252522c;
}

.botonesAntSig p {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    margin: auto 20px;
    padding: 0;
}

@media (max-width: 900px) {
    .añosProyectos{
        display: flex;
        flex-direction: column;
    }
    .botonesProyectos {
        width: 100%;
    }
    
    .años {
        width: 100%;
        margin-bottom: 20px;
    }
    .hamburgerAños {
        display: block;
    }
    .años h2 {
        display: none;
    }
    .años button {
        display: none;
    }
    .años.open h2 {
        display: block;
    }
    .años.open button {
        display: block;
    }

}