.fondoLogin {
    display: flex;
        justify-content: center;
        align-items: center;
    background-image: url("../images/propuesta2.jpg");
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.panelLogin {
    font-family: "Montserrat", sans-serif;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}
.panelLogin img {
    width: 50%;
}
.panelLogin h2 {
    font-family: "GarnetCapitalsBold";
    text-align: center;
    margin-top: 0;
}

.panelLogin form {
    display: flex;
    flex-direction: column;
}

.panelLogin label {
    margin-bottom: 15px;
}

.panelLogin input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
}

.panelLogin button {
    padding: 10px;
    background-image: linear-gradient(90deg, #a12326 20%, #570000);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.panelLogin button:hover {
    font-weight: 600;
    
}