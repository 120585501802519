.panelServicios {
    margin: 20px;
    margin-top: 0px;
    padding: 20px;
    box-shadow: 0px 0px 4px black;
    min-height: 600px;
}

.selector {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.selector button {
    padding: 15px 15px;
    font-size: medium;
    background: white;
    color: black;
    border: none;
    cursor: pointer;
    font-family: "GarnetCapitalsThin";
}

.grupo_servicios,
.objetivo {
    padding: 0px;
    min-height: 100px;
    color: #fff;
}

.grupo_servicios {
    display: flex;
        flex-wrap: wrap;
        padding: 5px;
        gap: 20px;
}

.servicio_individual {
    flex: 1 0 40%;
    margin: 0;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px black;
}


.textoServicio {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.servicio_individual img {
    width: 260px;
    float: left;
    height: auto;
    display: block;
    margin: 0 auto;
    border: 1px solid #8E8E8E;
}

.servicio_individual h1 {
    font-family: "GarnetCapitalsBold";
    font-size: large;
    text-align: left;
    margin: 0;
    padding: 10px;
    padding-bottom: 0px;
    color: #550514;
}

.servicio_individual p {
    font-size: small;
    text-align: justify;
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "Montserrat", sans-serif;
    white-space: pre-wrap;
}

.servicio_individual a {
    padding: 10px 15px;
    margin-left: 10px;
    margin-bottom: 2%;
    font-size: medium;
    background: linear-gradient(90deg, #a12326 20%, #570000);
    color: white;
    border-radius: 50px;
    width: fit-content;
    cursor: pointer;
    font-family: "GarnetCapitalsThin";
    box-shadow: 0px 0px 4px black;
    text-decoration: none;
}

@media (max-width: 1100px) {
    .grupo_servicios {
        flex-direction: column;
    }

    .servicio_individual {
        flex: 1 0 100%;
    }
    
}
@media (max-width: 550px) {
    .servicio_individual{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .servicio_individual img{
        max-width: 260px;
        width: 100%;
    }
    .textoServicio a {
        margin: 20px auto;
    }
}