.inicioDashboard {
    padding: 20px;
    font-family: "Montserrat", sans-serif;
}

.inicioDashboard button:hover {
    background-color: #5252522c;
}

.inicioDashboard h2 {
    margin-top: 0px;
}

.explicacionBoton {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.explicacionBoton button{
    margin-bottom: 0px;
}
.explicacionBoton p{
    margin-left: 16px;
    margin-right: 16px;
}

.botonesMover {
    display: flex;
    flex-direction: column;
}

.App {
    padding: 20px;
}

.App h1 {
    font-family: "GarnetCapitalsBold";
    margin-top: 0px;
}

.crud-table button {
    height: 40px;
    width: 10%;
    min-width: 100px;
    background: #9999992c;
    border: none;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: 1px solid #ddd;
    
}

.App button:hover {
    background-color: #5252522c;
}

.añadirFila {
    margin-bottom: 20px;
    width: 150px;
    height: 40px;
    min-width: 100px;
    background: #9999992c;
    border: none;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: 1px solid #ddd;
}

.crud-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.crud-form input,
.crud-form button {
    margin: 5px 0;
    padding: 10px;
    font-size: 16px;
}

.crud-table {
    width: 100%;
    border-collapse: collapse;
}

.crud-table th,
.crud-table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-family: "Montserrat", sans-serif;
}

.crud-table th {
    background-color: #f2f2f2;
}



.crud-table td {
    text-align: center;
    white-space: pre-wrap;
}

.cuadroJustificado {
    text-align: justify !important;
}

.crud-image {
    max-width: 150px;
    height: auto;
    display: block;
    margin: 0 auto;
    filter: drop-shadow(0px 0px 4px black);
}

.crud-table button {
    font-family: "Montserrat", sans-serif;
}

.modalDashboard {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    padding-right: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 40%;
    overflow-y: scroll;
    max-height: 90%;
    scrollbar-width: 10px;
    
}

.modalDashboard h1 {
    font-family: "GarnetCapitalsBold";
    margin-top: 0;
}

.modalDashboard div:not(.submitCancel) {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.añadirFecha {
    width: 50%;
}

.modalDashboard label {
    font-family: "GarnetCapitalsThin";
    font-size: small;
    color: #550514;
    font-weight: 600;
    margin-bottom: 5px;
}

.modalDashboard input {
    margin-top: 0px;
    font-size: medium;
    border-radius: 20px;
    padding: 5px 10px;
    border: 1px solid #333;
}

.modalDashboard select {
    margin-top: 0px;
    font-size: medium;
    /*
    */
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 5px 10px;
    border: 1px solid #333;
}



.modalDashboard input[type="file"] {

    border-radius: 0px;
    padding: 0px;
    border: none;
}

.modalDashboard textarea {
    margin-top: 0px;
    min-height: 100px;
    font-size: small;
    font-family: "arial";
    resize: none;
    border-radius: 10px;
    padding: 10px 10px;
    border: 1px solid #333;
}
.cuerpoNoticia { 
    margin-top: 0px;
    min-height: 300px !important;
    font-size: small;
    font-family: "arial";
    resize: none;
    border-radius: 10px;
    padding: 10px 10px;
    border: 1px solid #333;
}

.modalDashboard button {
    height: 40px;
    min-width: 100px;
    background: #9999992c;
    border: none;
    cursor: pointer;
    border: 1px solid #333;
}

.modalDashboard button:hover {
    background-color: #5252522c;
}

.modalDashboard img {
    filter: drop-shadow(0px 0px 4px black);
}

.submitCancel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.submitCancel button {
    width: 150px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.modal h1 {
    margin-top: 0;
}

.imagenActual{
    display: flex;
    flex-direction: column;
}
.loadingLabel {
    font-family: "Montserrat", sans-serif;
}
.linkVisualizador {
    max-width: 200px;
    overflow-wrap: break-word;
}
