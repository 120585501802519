@font-face {
    font-family: "GarnetCapitalsBold";
    src: url(./fonts/GarnetCapitals-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "GarnetCapitalsThin";
    src: url(./fonts/GarnetCapitals-Thin.ttf) format("truetype");
}

@font-face {
    font-family: "GarnetCapitalsRegular";
    src: url(./fonts/GarnetCapitals-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: url(./fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}

.montserrat-regular {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
}

.modalGeneral {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContentGeneral {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    width: 40%;
}

.modalContentGeneral img {
    height: 100%;
    width: 100%;
    display: block;
    border: 1px solid #8E8E8E;
}

@media (max-width: 900px) {
    .modalGeneral, .modalContentGeneral, .modalContentGeneral img {
        display: none;
    }
}