.panelMonitoreo {
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin: 20px;
    box-shadow: 0px 0px 4px black;
    margin-top: 0px;
}
.panelRegion {
    width: 30%;
}
.panelRegion img {
    width: 100%;
    box-shadow: 0px 0px 4px black;
    cursor: pointer;
}
.panelRegion img:hover {
    box-shadow: 0px 0px 8px black;
}
.panelZonas {
    width: 70%;
    text-align: center;
    padding-left: 60px;
    padding-right: 40px;
}
.panelZonas h1 {
    font-family: "GarnetCapitalsBold";
    font-size: medium;
    margin-top: 0px;
}
.panelZonas h2 {
    font-family: "GarnetCapitalsThin";
    font-size: medium;
    color: gray;
    margin-top: 0px;
}
.panelZonas p {
    font-family: "Montserrat";
    margin-top: 0px;
    font-size: medium;
}
.provincias {
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0px 0px 4px black;
    cursor: pointer;
}
.provincias:hover {
    box-shadow: 0px 0px 8px black;
}
.provincias:last-child {
    margin-bottom: 0px;
}
.fondoProvincia{
    display: flex;
}
.fondoProvincia img {
    width: 50%;
}
.iconoProvincia {
    position: absolute;
    top: 0;
    height: 100%;
    transform: translate(-50%);
    z-index: 1;
}
@media (max-width: 900px) {
    .panelMonitoreo {
        flex-direction: column;
    }
    .panelRegion {
        width: 100%;
    }
    .panelZonas {
        width: 100%;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;
    }
    .panelZonas h1 {
        margin-top: 10px;
    }
    .imagenIzquierda {
        width: 100%;
    }
    .fondoProvincia img {
        width: 100%;
    }
    .iconoProvincia{
        display: none;
    }
    .imagenDerecha {
        display: none;
    }
}