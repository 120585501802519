.panelVisualizadores {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px;
    box-shadow: 0px 0px 4px black;
    margin-top: 0px;
}
.panelVisualizadores h1:first-child {
    font-family: "GarnetCapitalsBold";
    font-size: large;
    margin-top: 0px;
    text-align: center;
}

.panelVisualizadores h2 {
    font-family: "GarnetCapitalsThin";
    font-size: large;
    color: gray;
    margin-top: 0px;
    text-align: center;
}
.selectorZona {
    display: flex;
    margin-bottom: 20px;
    height: 100px;
}
.selectorZona img {
    height: 90%;
}
.selectorZona h1 {
    font-family: "GarnetCapitalsBold";
    font-size: large;
    color: white;
    width: 100%;
}
.botonRegion{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 50%;
    margin-right: 20px;
    cursor: pointer;
    box-shadow: 0px 0px 4px black;
    background-image: url("../images/fondo_boton_region.png");
    background-size: cover;
    background-position: center;
    align-items: center;
}
.botonRegion:hover{
    box-shadow: 0px 0px 8px black;
}
.region{
    display: flex;
    width: 100px;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.region img {
    height: 80%;
}

.botonesProvincia {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 50%;
    
}
.botonesProvincia img {
    height: 100%;
    
}
.provincia1, .provincia2, .provincia3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    width: 100%;
    cursor: pointer;
    box-shadow: 0px 0px 4px black;
    padding: 10px;
    gap: 10px;
}
.provincia1:hover,
.provincia2:hover,
.provincia3:hover{
    box-shadow: 0px 0px 12px black;
}
.provincia1 {
    background-image: url("../images/fondo_boton_elqui.png");
}
.provincia2 {
    background-image: url("../images/fondo_boton_limari.png");
}
.provincia3 {
    background-image: url("../images/fondo_boton_choapa.png");
}
.panelVisualizadores iframe {
    width: 100%;
    height: 600px;
    /* Ajusta la altura según tus necesidades */
    border: none;
    box-shadow: 0px 0px 4px black;
}

@media (max-width: 900px) {
    .selectorZona {
        flex-direction: column;
        height: auto;
    }
    .botonesProvincia {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }
    .botonRegion {
        width: 100%;
        height: 100px;
        margin-bottom: 10px;
    }
    .provincia1,
    .provincia2,
    .provincia3 {
        height: 80px;
        width: auto;
    }
}