.reporte {
    display: flex;
    flex-direction: column;
}
.reporte h1 {
    font-family: "GarnetCapitalsBold";
    font-size: 20px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
}
.reporte h2 {
    font-family: "GarnetCapitalsThin";
    font-size: medium;
    color: gray;
    margin-top: 0px;
    text-align: center;
}
.reporte h3 {
    font-family: "GarnetCapitalsThin";
    font-size: 15px;
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    margin: 0px auto;
    padding: 5px;
}
.reporte h4 {
    font-family: "GarnetCapitalsThin";
    font-size: small;
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    margin: 0px auto;
    padding: 5px;
}
.reporteElqui {
    display: flex;
    flex-direction: column;
    background-color: black;
    padding: 10px 20px;
    background-image: url("../images/fondo_reporte_elqui.jpg");
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 4px black;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 20px;
}
.reporteLimari {
    display: flex;
    flex-direction: column;
    background-color: black;
    padding: 10px 20px;
    background-image: url("../images/fondo_reporte_limari.jpg");
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 4px black;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 20px;
}
.reporteChoapa {
    display: flex;
    flex-direction: column;
    background-color: black;
    padding: 10px 20px;
    background-image: url("../images/fondo_reporte_choapa.jpg");
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 4px black;
    cursor: pointer;
    border-radius: 20px;
}
.reporteElqui img {
    align-self: center;
    width: 40%;
    max-width: 350px;
}
.reporteElqui:hover, .reporteLimari:hover, .reporteChoapa:hover {
    box-shadow: 0px 0px 12px black;
}
.reporteLimari img {
    align-self: center;
    width: 40%;
    max-width: 350px;
}
.reporteChoapa img {
    align-self: center;
    width: 40%;
    max-width: 350px;
}
.volumenActual {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 11px;
}
.volumenTotalContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.volumenTotal {
    font-family: "Montserrat", sans-serif;
    color: gray;
    font-size: 11px;
}
.estadoCuenca {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: x-small;
    border-top: 1px solid white;
    padding: 5px;
    margin-top: 10px;
    text-align: center;
}
.reporteTotal {
    padding: 0px 20px;
    margin-bottom: 20px;
}
.volumenActualTotal {
    font-family: "Montserrat", sans-serif;
    color: gray;
    font-size: 11px;
}
.totalRegional {
    font-family: "GarnetCapitalsThin";
    font-size: 15px;
    color: black !important;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    margin: 0px auto;
    padding: 5px;
}
.modalReporte {
    display: flex;
    flex-direction: column;
}
.modalReporte h1{
    font-family: "GarnetCapitalsBold";
    font-size: 25px;
    align-self: center;
    margin: 0px;
}
.modalReporte h2{
    font-family: "GarnetCapitalsThin";
    font-size: medium;
    color: gray;
    align-self: center;
    margin: 0px;
    margin-bottom: 10px;
}
.modalReporte h2{
    font-family: "GarnetCapitalsThin";
    font-size: medium;
    color: gray;
    text-align: center;
    margin: 0px;
    margin-bottom: 10px;
}
.contenedorFechaReporte {
    display: flex;
    justify-content: center;
}
.modalReporteTotal {
    display: flex;
    flex-direction: column;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    padding: 10px;
}
.modalReporteTotal p {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    align-self: center;
    margin: 0px;
}
.modalReporteTotal label {
    font-family: "Montserrat", sans-serif;
    font-size: x-small;
    align-self: left;
    margin: 0px;
}
.modalReporteTotal label:last-child {
    font-family: "Montserrat", sans-serif;
    align-self: end;
    margin: 0px;
}
.modalReporte h3 {
    font-family: "GarnetCapitalsBold";
    font-size: 15px;
    align-self: center;
    margin: 10px;
}
.modalProvincias {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.modalNombreProvincia {
    font-family: "GarnetCapitalsThin" !important;
    font-weight: 100;
    margin: 0px;
    text-align: center;
    border-bottom: 3px solid black;
}
.modalNombreProvincia_limari {
    font-family: "GarnetCapitalsThin" !important;
    font-weight: 100;
    margin: 0px;
    text-align: center;
    border-bottom: 3px solid #9A1D17;
}
.modalTotalAcumulado {
    font-family: "GarnetCapitalsThin" !important;
    font-weight: 100 !important;
    margin: 0px auto !important;
    padding: 5px 30px !important;
    border-radius: 10px !important;
    border: 5px solid black !important;
    width: fit-content;
    font-size: small !important;
}
.modalTotalAcumulado_limari {
    font-family: "GarnetCapitalsThin" !important;
    font-weight: 100 !important;
    margin: 0px auto !important;
    padding: 5px 30px !important;
    border-radius: 10px !important;
    border: 5px solid #9A1D17 !important;
    width: fit-content;
    font-size: small !important;
}
.modalProvincias h2 {
    font-family: "Montserrat", sans-serif;
    font-size: medium;
    color: black;
    margin: 0px;
    margin-top: 5px;
    text-align: center;
}
.modalProvincias h4 {
    font-family: "Montserrat", sans-serif;
    font-size: small;
    font-weight: 300;
    color: gray;
    margin: 5px;
    text-align: center;
}
.volumenActualModal {
    font-family: "Montserrat", sans-serif;
    color: gray;
    font-size: 11px;
    align-self: left;
    margin: 0px;
}
.contenedorMapa {
    display: flex;
    justify-content: center;
}
.mapaColor{
    width: 60%;
    filter: drop-shadow(0px 0px 4px black);
}

.contenedorFondoImagen {
    width: 40%;
    display: flex;
    align-content: center;
    margin: 0 auto;
    background-color: transparent;
}
.fondoImagenModal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    border-top: 20px solid black;
    border-left: 20px solid black;
    border-bottom: 20px solid #9A1D17;
    border-right: 20px solid #9A1D17;
    overflow-y: auto;
}
.modalButtons {
    display: flex;
    justify-content: space-between;
}
.no-capture {
    height: 20px;
    width: 10%;
    min-width: 100px;
    background: white;
    border: none;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: 1px solid #333;
    
}
.no-capture:hover {
    background-color: rgb(231, 231, 231);
}
.contenedorLogotipos {
    display: flex;
    flex-direction: column;
}
.contenedorLogotipos img{
    width: 100%;
}
.contenedorLogotipos p{
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    text-align: justify;
    color: gray;
}
.contenedorProvinciaModal {
    width: 33.33%;
}

@media (max-width: 1600px) {

}