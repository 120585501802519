.panelLineas {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5%;
    box-shadow: 0px 0px 4px black;
    margin: 20px;
    margin-top: 0px;
    padding: 20px;

}
.panelLineas * {
    /*
    border: 1px solid black;
    */
}
.selectorLineas {
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-bottom: 20px;
}
.selectorLineas button {
    padding: 15px 15px;
    font-size: medium;
    background: white;
    color: black;
    border: none;
    cursor: pointer;
    font-family: "GarnetCapitalsThin";
}

.selectorLineas button:hover {
    background-color: #aaaaaa2c;
}

.selectorLineas button.active {
    font-family: "GarnetCapitalsBold";
    border-top: 2px solid #333;
}
.swipeableLineas{
    width: 100%;
    
}
.panelLineas h2 {
    font-family: "GarnetCapitalsBold";
    font-size: larger;
    text-align: center;
    margin-bottom: 0px;
}
.panelLineas h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "GarnetCapitalsThin";
    font-size: larger;
    text-align: center;
    border-bottom: 5px solid #333;
    margin-bottom: 10px;
    margin-top: 0px;
    
    height: 100px;
}
.añoLinea {
    margin-top: 0px;
    color: gray;
}
/*-----------------------------Primera página-------------------------------*/
.primeraPestaña {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 2px solid #333;
    margin: 20px;
    margin-top: 0px;
}
.panelCentrado1{
    width: 50%;
    align-self: center;
}
.primeraPestaña p {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    white-space: pre-wrap;
    
}
.principio p {
    font-family: "Montserrat", sans-serif;
    text-align: justify;
    white-space: pre-wrap;
}
.imagenesPrimeraPestañas{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1%;
}

.imagenPrommra img{
    width: 100%;
    border: 1px solid #8E8E8E;
}
.principios {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1%;
}
.principio {
    width: 100%;
    border-right: 1px solid #777777;
    padding-right: 1%;
    padding-left: 1%;
}

.principio:last-child {
    border-right: none;
    padding-right: 0;
}

.principio:first-child {
    padding-left: 0;
}
.principio img{
    width: 100%;
    border: 1px solid #8E8E8E;
}
@media (max-width: 1200px) {
    .panelCentrado1 {
        width: 100%;
    }
    .principios {
        display: flex;
        
    }
    .principio {
        width: 100%;
    }
}
@media (max-width: 800px) {
    .imagenesPrimeraPestañas {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .principios {
        display: flex;
        flex-direction: column;
    }
    .principio {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        border: none;
    }
}
/*-----------------------------Segunda página-------------------------------*/
.segundaPestaña {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 2px solid #333;
    margin: 20px;
    margin-top: 0px;
}
.segundaPestaña p {
    font-family: "Montserrat", sans-serif;
    text-align: justify;
    width: 100%;
    white-space: pre-wrap;
}
.lineas {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.linea {
    border-right: 1px solid #777777;
    padding-right: 1%;
    padding-left: 1%;
    width: 20%;
}
.linea:last-child {
    border-right: none;
    padding-right: 0;
}
.linea:first-child {
    padding-left: 0;
}
.linea img{
    width: 100%;
    border: 1px solid #8E8E8E;
}
@media (max-width: 1200px){
    .segundaPestaña {
        margin: 0px;
    }
    .lineas {
        display: flex;
        flex-direction: column;
    }
    .linea {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        border: none;
    }
}