.Quienes_somos *{
    /*
    border: 1px solid black;
    */
}
.panel{
    margin: 20px;
    margin-top: 0px;
    padding: 20px;
    box-shadow: 0px 0px 4px black;
    min-height: 600px;
}
.selectorMiembros{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.selectorMiembros button {
    padding: 15px 15px;
    font-size: medium;
    background: white;
    color: black;
    border: none;
    cursor: pointer;
    font-family: "GarnetCapitalsThin";
}
.selectorMiembros button:hover {
    background-color: #aaaaaa2c;
}
.selectorMiembros button.active {
    font-family: "GarnetCapitalsBold";
    border-top: 2px solid #333;
}
.equipo, .objetivo {
    padding: 0px;
    min-height: 100px;
    color: #fff;
}
.equipo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.miembro {
    box-shadow: 0px 0px 4px black;
    flex: 1 1 calc(33.333% - 20px);
    display: flex;
    flex-direction: row;
    margin: 10px;
}
.miembro:hover {
    cursor: pointer;
    background-color: #9999992c;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content {
    background-color: white;
    width: 60%;
    height: auto;
    padding: 20px;
    border-radius: 4px;
    overflow-y: auto;
}
.modal-content img {
    width: 50%;
    float: left;
    display: block;
    margin: 0 auto;
    margin-right: 10px;
    border: 1px solid #8E8E8E;
}
.modal-content h1 {
    font-family: "GarnetCapitalsThin";
    font-size: x-large;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: gray;
}
.modal-content h2 {
    font-family: "GarnetCapitalsBold";
    font-size: x-large;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: #550514;
}
.textoMiembro {
    display: flex;
    flex-direction: column;  
}
.miembro img {
    height: 200px;
    width: 200px;
    border: 1px solid #8E8E8E;
}
.miembro h1 {
    font-family: "GarnetCapitalsThin";
    font-size: small;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: gray;
}
.miembro h2 {
    font-family: "GarnetCapitalsBold";
    font-size: medium;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: #550514;
}
.miembro p {
    font-size: small;
    text-align: justify;
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "Montserrat", sans-serif;
    white-space: pre-wrap;
}
.titulo {
    font-family: "GarnetCapitalsThin";
    font-size: large;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: gray;
}
.descripcion {
    font-family: "Montserrat", sans-serif;
    font-size: medium;
    text-align: justify;
    margin: 0;
    padding: 10px;
    color: black;
}
.correo {
    font-family: "Montserrat", sans-serif;
    font-size: medium;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: black;
}
.objetivo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3px;
    box-shadow: 0px 0px 4px black;
    color: black;
}
.infoPrommra {
    display: flex;
    flex-direction: column;
   align-items: center;
}
.objetivo img {
    width: 200px;
    height: 200px;
    float: left;
    display: block;
    margin: 0 auto;
}
.objetivo h2 {
    font-family: "GarnetCapitalsBold";
    font-size: large;
    text-align: center;
    margin: 0;
    padding: 10px;
    color: #550514;
}
.objetivo p {
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "Montserrat", sans-serif;
    white-space: pre-wrap;
}
.objetivo ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px;
    width: 30%;
    text-align: justify;
    color: black;
}
.objetivo li {
    font-family: "Montserrat", sans-serif;
    width: fit-content;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: black;
}
@media (max-width: 1100px) {

    .modal-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 20px;
        width: 70%;
        max-height: 70%;
    }



    .modal-content h1 {
        text-align: center;
        font-size: large;
    }
    .modal-content h2 {
        text-align: center;
        font-size: large;
    }
    .titulo {
        text-align: justify;
        font-size: medium;
    }
}
@media (max-width: 1350px) {
    .miembro {
        flex: 1 1 calc(50% - 20px);
    }
}
@media (max-width: 900px) {

    .miembro {
        flex: 1 1 100%;
        min-height: 100px;
    }
}

@media (max-width: 500px) {
    .objetivo ul {
        width: 80%;
    }
    .miembro {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .miembro h1, .miembro h2{
        text-align: center;
    }
    .panel {
        margin: 0px;
        padding: 0px;
        padding-top: 20px;
    }
    .modal-content img {
        width: 100%;
        padding: 0px;
    }
}

@media (max-width: 300px) {
    .selectorMiembros {
        flex-direction: column;
    }

}