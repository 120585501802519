/* Navbar2.css */

/* Estilo base para el navbar */
.navbar2 {
    display: flex;
    flex-direction: row;
    /* Elementos en fila por defecto */
    justify-content: space-around;
    /* Distribuye el espacio entre los elementos */
    align-items: center;
    /* Centra los elementos verticalmente */
    background-color: white;
    /* Color de fondo */
    color: black;
    /* Color del texto */
    padding: 20px 0;
    /* Espaciado interno */
    
    
}
/* Estilo para los enlaces */
.navbar2 a {
    
    color: black;
    /* Color del texto */
    text-decoration: none;
    /* Elimina el subrayado */
    padding: 10px 20px;
    /* Espaciado interno */
    font-family: "GarnetCapitalsThin";
}
.navbar2 a:hover {
    background-color: #9999992c;
}

/* Estilo para el botón hamburguesa - oculto por defecto */
.hamburger2 {
    display: none;
    cursor: pointer;
    /* Cambia el cursor a modo clic */
}

/* Cuando el navbar está abierto, cambia la dirección de los elementos a columna */
.navbar2.open {
    flex-direction: column;
    /* Elementos en columna */
}
.nav-items2 a.active {
    font-family: "GarnetCapitalsBold";
    border-top: 5px solid #333;
}
/* Media query para pantallas pequeñas (teléfonos móviles) */
@media (max-width: 1200px) {

    .hamburger2 {
        display: block;
        text-align: center;
        font-size: xx-large;
    }
    .nav-items2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .nav-items2 a {
        display: none;
        font-family: "GarnetCapitalsThin";
        
    }
    .navbar2.open .nav-items2 a {
        display: block;
        text-align: center;
        width: fit-content;
    }
    .navbar2 {
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
    }
}