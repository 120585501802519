.Quienes_somos * {
    /*
    border: 1px solid black;
    */
}

.panelNoticias {
    margin: 20px;
    margin-top: 0px;
    padding: 20px;
    box-shadow: 0px 0px 4px black;
    min-height: 600px;
}

.noticias {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0px;
    min-height: 100px;
}

.noticia {
    box-shadow: 0px 0px 4px black;
    flex: 1 1 30%;
    display: flex;
    flex-direction: row;
    margin: 10px;
}
.noticia:hover {
    cursor: pointer;
    background-color: #9999992c;
}

.contenedorImagenNoticia {
    display: flex;
    align-items: center;
}

.noticia img {
    width: 200px;
    border: 1px solid #8E8E8E;
}

.noticia h1 {
    font-family: "GarnetCapitalsBold";
    font-size: medium;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: #550514;
}

.noticia h2 {
    font-family: "GarnetCapitalsThin";
    font-size: small;
    text-align: left;
    margin: 0;
    padding: 10px;
    color: gray;
    padding-top: 0px;
    padding-bottom: 0px;
}

.noticia p {
    font-size: small;
    text-align: justify;
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "Montserrat", sans-serif;
    white-space: pre-wrap;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 500px) {

    .noticia {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .noticia h1,
    .noticia h2 {
        text-align: center;
    }

    .panelNoticias {
        margin: 0px;
        padding: 0px;
        padding-top: 20px;
    }

    .modal-content img {
        width: 100%;
        padding: 0px;
    }
}