.panelForm {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 0px;
    padding: 20px;
    box-shadow: 0px 0px 4px black;   
}
.panelForm h1 {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: min(10vw, 80px);
    font-family: "GarnetCapitalsBold";
    color: #b52727;
    
}
.panelForm h3 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0px;
    font-family: "GarnetCapitalsThin";
    color: #550514;
}
.panelForm h4 {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 5px;
    font-family: "GarnetCapitalsThin";
    font-size: small;
    color: #550514;
}
.campos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 10%;
    margin-bottom: 20px;
}
.campos div {
    flex: 1 1 calc(50% - 20px);
    display: flex;
    flex-direction: column;
    width: 100%;
}
.panelForm input {
    margin-top: 0px;
    font-size: medium;
    border-radius: 20px;
    padding: 5px 10px;
    border: 1px solid #333;
}
.panelForm textarea {
    
    margin-top: 0px;
    min-height: 100px;
    font-size: small;
    font-family: "arial";
    resize: none;
    border-radius: 10px;
    padding: 10px 10px;
    border: 1px solid #333;
}
.gps{
    height: 120px;
    border-radius: 10px;
    border: 1px solid #333;
    
}

.captcha {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.btnEnviar{
    padding: 10px 15px;
    
    font-size: medium;
    background: linear-gradient(90deg, #a12326 20%, #570000);
    color: white;
    border-radius: 50px;
    width: 100px;
    height: 40px;
    cursor: pointer;
    font-family: "GarnetCapitalsThin";
    text-decoration: none;
    align-self: center;
}

.enviarCargando {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.textoCargando {
    font-family: "GarnetCapitalsThin";
    font-size: small;
    width: fit-content;
    margin: 0px;
}

@media (max-width: 660px) {
    .campos {
        flex-direction: column;
        margin: 0px;
        margin-bottom: 20px;
    }
    .panelForm h1 {
        font-size: min(9vw, 80px);
    }
}

.ToastContainer {
    background: linear-gradient(90deg, #a12326 20%, #570000);
    color: white;
}
