.aviso-construccion {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.aviso-construccion h1 {
    align-items: center;
    font-family: "GarnetCapitalsBold";
    font-size: large;
    color: white;
    text-align: center;
    margin-bottom: 0px;
}
.aviso-construccion p {
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: large;
    color: white;
    text-align: center;
}
.aviso-construccion img {
    width: 200px;
    margin-bottom: 0px;
}
.cerrar-aviso {
    position: absolute;
    right: 10px;
    top: 5px;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 20px;
    cursor: pointer;
}
.cerrar-aviso:hover{
    background-color: rgba(88, 88, 88, 0.7);
}