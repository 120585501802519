.Inicio{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.backgroundPanel{
    background: white;
}

